import { getCurrentUser } from 'helpers/currentUser';


type ValidGroup = 'admin' | 'operator' | 'production' | 'api';

/**
 * Checks if a user belongs to any of the specified groups
 * @param {ValidGroup[]} groupNames - Single group or array of groups to check
 * @returns {boolean} - True if user is in any of the specified groups
 */
export function userInGroup(groupNames: readonly ValidGroup[]): boolean {
  return getCurrentUser().inGroup(groupNames);
}

/**
 * Adds the item to the items array if the user belongs to any of the specified groups
 * @param {ValidGroup[]} groups - Array of groups to check
 * @param {T} item - The item to add
 * @param {T[]} items - The items to add to
 * @returns {void}
 */
export const addIfInGroup = <T extends object>(
  groups: readonly ValidGroup[],
  item: T,
  items: T[]): void => {
  if (userInGroup(groups)) {
    items.push(item)
  }
};

/**
 * Adds the item to the items array if the user does not belong to any of the specified groups *
 * @param {ValidGroup[]} groups - Array of groups to check
 * @param {T} item - The item to add
 * @param {T[]} items - The items to add to
 * @returns {void}
 */
export const addIfNotInGroup = <T extends object>(
  groups: readonly ValidGroup[],
  item: T,
  items: T[]): void => {
  if (!userInGroup(groups)) {
    items.push(item)
  }
}